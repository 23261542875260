import { Classes } from "@blueprintjs/core";
import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";

import {
  BUTTON_OVERRIDE_SMALL,
  HexButton,
} from "../../hex-components/HexButton.js";
import { HexInputGroup } from "../../hex-components/HexInput.js";
import { HexSpinner } from "../../hex-components/HexSpinner.js";
import { Keys } from "../../util/Keys";
import { CrossSmallIcon, SearchIcon } from "../icons/CustomIcons";

export interface FilterProps {
  filter: string;
  onSetFilter: (newFilter: string) => void;
  minimal?: boolean;
  round?: boolean;
  small?: boolean;
  large?: boolean;
  loading?: boolean;
  placeholder?: string;
  showIcon?: boolean;
  className?: string;
  disabled?: boolean;
  onFocus?: () => void;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  onEnter?: () => void;
  onResetFilter?: () => void;
  autoFocus?: boolean;
}

const Filter = styled(HexInputGroup)<{ minimal: boolean }>`
  && {
    position: relative;

    display: flex;
    flex: none;
    align-items: center;

    > .${Classes.INPUT_ACTION} {
      height: 100%;
      display: flex;
      align-items: center;
    }

    ${({ minimal }) =>
      minimal &&
      css`
        > .${Classes.ICON} {
          left: 8px;
        }

        > .${Classes.INPUT} {
          height: 30px;
          padding-left: 34px !important;

          line-height: 30px;

          background: transparent;
          border-radius: 0;
          box-shadow: none;
        }

        > .${Classes.INPUT_ACTION} > .${Classes.BUTTON} {
          top: 0;
          right: 0;

          margin: 5px;
        }
      `}
  }
`;

const ClearFilterButton = styled(HexButton)<{ $filtered: boolean }>`
  position: relative;
  top: 0;
  right: 0;

  opacity: 0;

  pointer-events: none;

  ${({ $filtered }) => $filtered && `opacity: 1; pointer-events: auto;`}

  &&&&${BUTTON_OVERRIDE_SMALL} {
    margin: 2px;
    border-radius: 50%;
  }
`;

export const ListFilter: React.FunctionComponent<FilterProps> = ({
  autoFocus = false,
  className,
  disabled = false,
  filter,
  inputRef,
  large = false,
  loading,
  minimal = false,
  onEnter,
  onFocus,
  onResetFilter,
  onSetFilter,
  placeholder = "Filter...",
  round = false,
  showIcon = true,
  small = false,
}) => {
  const onChangeCallback = useCallback(
    (evt: React.FormEvent<HTMLInputElement>) => {
      onSetFilter(evt.currentTarget.value);
    },
    [onSetFilter],
  );

  const keyPressCallback = useCallback(
    (evt: React.KeyboardEvent<HTMLInputElement>) => {
      if (evt.key === Keys.ESCAPE) {
        evt.preventDefault();
        onSetFilter("");
      }
      if (onEnter && evt.key === Keys.ENTER) {
        onEnter();
      }
    },
    [onEnter, onSetFilter],
  );

  const clearFilterCallback = useCallback(() => {
    onResetFilter?.();
    onSetFilter("");
  }, [onSetFilter, onResetFilter]);

  const loadingFilterResult = useMemo(
    () => loading && filter.length > 0,
    [loading, filter],
  );

  return (
    <Filter
      autoComplete="off"
      autoFocus={autoFocus}
      className={className}
      disabled={disabled}
      id="filter"
      inputRef={inputRef}
      large={large}
      leftIcon={showIcon ? <SearchIcon /> : undefined}
      minimal={minimal}
      placeholder={placeholder}
      rightElement={
        loadingFilterResult ? (
          <HexSpinner />
        ) : (
          <ClearFilterButton
            $filtered={filter.length > 0}
            icon={<CrossSmallIcon />}
            minimal={true}
            small={true}
            onClick={clearFilterCallback}
          />
        )
      }
      round={round}
      small={small}
      value={filter}
      onChange={onChangeCallback}
      onFocus={onFocus}
      onKeyDown={keyPressCallback}
    />
  );
};
